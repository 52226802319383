<template>
  <div class="container mt-5">
    <div>
              <h1>
                นโยบายเว็บไซต์ของกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน Website
                Policy of Department of Alternative Energy Development and
                Efficiency จัดทำเมื่อวันที่ 15 กันยายน 2557
              </h1>
              <p>
                <b>1. วัตถุประสงค์
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานจัดทำเว็บไซต์ขึ้นเพื่อให้บริการ
                ข้อมูลข่าวสารแก่ประชาชนทั่วไป
                และบุคลากรในสังกัดกรมพัฒนาพลังงานทดแทนและอนุรักษ์ พลังงาน
                ในการใช้บริการเว็บไซต์ของผู้ใช้บริการจะอยู่ภายใต้เงื่อนไขและข้อกำหนดดังต่อไปนี้
                ผู้ใช้บริการจึงควรศึกษาเงื่อนไข
                และข้อกำหนดการใช้งานเว็บไซต์และ/หรือเงื่อนไขและข้อตกลง
                อื่นใดที่กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานได้แจ้งให้ทราบบนเว็บไซต์โดยละเอียด
                ก่อนการเข้าใช้บริการ
                ทั้งนี้ในการใช้บริการให้ถือว่าผู้ใช้บริการได้ตกลงที่จะปฏิบัติตามเงื่อนไข
                และข้อกำหนดการให้บริการที่กำหนดไว้นี้หากผู้ใช้บริการไม่ประสงค์ที่จะผูกพันตามข้อกำหนด
                และเงื่อนไขการให้บริการ
                ขอความกรุณาท่านยุติการเข้าชมและใช้งานเว็บไซต์นี้ในทันที</b>
              </p>
              <p>
                <b>2. เงื่อนไขและข้อกำหนดการใช้งานเว็บไซต์</b>
                <br />2.1 ผู้ใช้บริการอาจได้รับ เข้าถึง สร้าง ส่งหรือแสดงข้อมูล
                เช่น ไฟล์ข้อมูล ข้อความ ลายลักษณ์อักษร
                ซอฟต์แวร์คอมพิวเตอร์ดนตรีไฟล์เสียง หรือเสียงอื่นๆ ภาพถ่าย วิดีโอ
                หรือรูปภาพอื่นๆ โดยเป็นส่วนหนึ่งของบริการหรือโดยผ่านการใช้บริการ
                ซึ่งต่อไปนี้จะ เรียกว่า “เนื้อหา” <br />
                2.2 เนื้อหาที่นำเสนอต่อผู้ใช้บริการ
                อาจได้รับการคุ้มครองโดยสิทธิในทรัพย์สิน
                ทางปัญญาของเจ้าของเนื้อหานั้น
                ผู้ใช้บริการไม่มีสิทธิเปลี่ยนแปลงแก้ไข จำหน่ายจ่ายโอนหรือ
                สร้างผลงานต่อเนื่อง
                โดยอาศัยเนื้อหาดังกล่าวไม่ว่าจะทั้งหมดหรือบางส่วน
                เว้นแต่ผู้ใช้บริการจะ ได้รับอนุญาตโดยชัดแจ้ง
                จากเจ้าของเนื้อหานั้น <br />
                2.3 ผู้ใช้บริการอาจพบเนื้อหาที่ไม่เหมาะสม หรือหยาบคาย
                อันก่อให้เกิดความ ไม่พอใจ ภายใต้ความเสี่ยงของตนเอง
                <br />
                2.4 กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานทรงไว้ซึ่งสิทธิในการ
                คัดกรอง ตรวจทาน ทำเครื่องหมาย เปลี่ยนแปลง แก้ไข ปฏิเสธ
                หรือลบเนื้อหาใดๆ ที่ไม่ เหมาะสมออกจากบริการ
                ซึ่งกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานอาจจัดเตรียม 2
                เครื่องมือในการคัดกรองเนื้อหาอย่างชัดเจน โดยไม่ขัดต่อกฎหมาย
                กฎระเบียบของทางราชการที่ เกี่ยวข้อง <br />
                2.5 เว็บไซต์นี้อาจหยุดให้บริการเป็นการชั่วคราวหรือถาวร
                หรือยกเลิกการ ให้บริการ แก่ผู้ใช้บริการรายใดเป็นการเฉพาะ
                หากการให้บริการดังกล่าวส่งผลกระทบต่อ ผู้ใช้บริการอื่นๆ
                หรือขัดแย้งต่อกฎหมาย โดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้า
                <br />
                2.6 การหยุดหรือการยกเลิกบริการตามข้อ <br>2.5
                ผู้ใช้บริการจะไม่สามารถเข้าใช้ บริการ
                และเข้าถึงรายละเอียดบัญชีของผู้ใช้บริการ ไฟล์เอกสารใดๆ
                หรือเนื้อหาอื่นๆ ที่อยู่ใน บัญชีของผู้ใช้บริการได้<br />
                2.7 ในกรณีที่เว็บไซต์นี้หยุดให้บริการเป็นการถาวร
                หรือยกเลิกบริการแก่ ผู้ใช้บริการ
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานมีสิทธิในการลบข้อมูลต่างๆ
                ที่อยู่ใน
                บัญชีของผู้ใช้บริการได้โดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้า
              </p>
              <p>
                <b>3. สิทธิหน้าที่และความรับผิดชอบของผู้ใช้บริการ</b> <br />3.1 ผู้ใช้บ
                ริการจะให้ข้อมูลเกี่ยวกับตนเอง เช่น ข้อมูลระบุตัวตนหรือ
                รายละเอียดการติดต่อ ที่ถูกต้อง เป็นจริง และเป็นปัจจุบันเสมอ
                แก่เว็บไซต์อันเป็นส่วนหนึ่งของ กระบวนการลงทะเบียนใช้บริการ
                หรือการใช้บริการที่ต่อเนื่อง <br />3.2
                ผู้ใช้บริการจะใช้บริการเว็บไซต์นี้เพื่อวัตถุประสงค์ที่ได้รับอนุญาตตาม
                ข้อกำหนดของกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                และไม่ขัดต่อกฎหมาย กฎ ระเบียบ ข้อบังคับ
                หลักปฏิบัติที่เป็นที่ยอมรับโดยทั่วไป <br />3.3
                ผู้ใช้บริการจะไม่เข้าใช้หรือพยายามเข้าใช้บริการหนึ่งบริการใดโดยวิธีอื่น
                รวมถึง การใช้วิธีการอัตโนมัติ(การใช้สคริปต์)
                นอกจากช่องทางที่กรมพัฒนาพลังงานทดแทน
                และอนุรักษ์พลังงานจัดเตรียมไว้ให้เว้นแต่ผู้ใช้บริการจะได้รับอนุญาตจากกรมพัฒนาพลังงาน
                ทดแทนและอนุรักษ์พลังงาน โดยชัดแจ้งให้ทำเช่นนั้นได้ <br />3.4
                ผู้ใช้บริการจะไม่ทำหรือมีส่วนร่วมในการขัดขวางหรือรบกวนบริการของ
                เว็บไซต์รวมทั้งเครื่องแม่ข่ายและเครือข่ายที่เชื่อมต่อกับบริการ
                <br />3.5 ผู้ใช้บริการจะไม่ทำสำเนา คัดลอก ทำซ้ำ ขาย แลกเปลี่ยน
                หรือขายต่อ บริการ เพื่อวัตถุประสงค์ใดๆ
                เว้นแต่ผู้ใช้บริการจะได้รับอนุญาตจากกรมพัฒนาพลังงานทดแทน
                และอนุรักษ์พลังงาน โดยชัดแจ้งให้ทำเช่นนั้นได้ <br />3 3.6
                ผู้ใช้บริการมีหน้าที่ในการรักษาความลับของรหัสผ่านที่เชื่อมโยงกับบัญชีใดๆ
                ที่ใช้ในการเข้าถึงบริการ <br />3.7
                ผู้ใช้บริการจะเป็นผู้รับผิดชอบแต่เพียงผู้เดียวต่อบุคคลใดๆ รวมถึง
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                ในความเสียหายอันเกิดจากการละเมิด ข้อกำหนด
              </p>
              <p>
               <b>4. การเชื่อมโยงกับเว็บไซต์อื่นๆ</b>  <br />4.1
                การเชื่อมโยงไปยังเว็บไซต์อื่นเป็นเพียงการให้บริการเพื่ออำนวยความ
                สะดวก แก่ผู้ใช้บริการเท่านั้น
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานมิได้มีส่วน เกี่ยวข้อง
                หรือมีอำนาจควบคุม รับรอง ความถูกต้อง ความน่าเชื่อถือ
                ตลอดจนความรับผิดชอบ ในเนื้อหาข้อมูลของเว็บไซต์นั้นๆ
                และกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานไม่
                รับผิดชอบต่อเนื้อหาใด ๆ
                ที่แสดงบนเว็บไซต์อื่นที่เชื่อมโยงมายังเว็บไซต์ของกรมพัฒนาพลังงาน
                ทดแทนและอนุรักษ์พลังงาน หรือต่อความเสียหายใดๆ
                ที่เกิดขึ้นจากการเข้าเยี่ยมชมเว็บไซต์
                ดังกล่าวและการเชื่อมโยงมายังเว็บไซต์ของกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                <br />4.2
                กรณีต้องการเชื่อมโยงมายังเว็บไซต์ของกรมพัฒนาพลังงานทดแทนและ
                อนุรักษ์พลังงาน ผู้ใช้บริการสามารถเชื่อมโยง
                มายังหน้าแรกของเว็บไซต์ได้โดยแจ้งความ ประสงค์เป็นหนังสือ
                แต่หากต้องการเชื่อมโยงมายังหน้าภายในของเว็บไซต์นี้จะต้องได้รับความ
                ยินยอมเป็นหนังสือจากกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานแล้วเท่านั้น
                และในการ ให้ความยินยอมดังกล่าว
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานขอสงวนสิทธิ์ที่จะ
                กำหนดเงื่อนไขใดๆ
                ไว้ด้วยก็ได้ในการที่เว็บไซต์อื่นที่เชื่อมโยงมายังเว็บไซต์ของกรมพัฒนา
                พลังงานทดแทนและอนุรักษ์พลังงานจะไม่รับผิดชอบต่อเนื้อหาใดๆ
                ที่แสดงบนเว็บไซต์ที่
                เชื่อมโยงมายังเว็บไซต์ของกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                หรือต่อความเสียหาย ใดๆ ที่เกิดขึ้นจากการใช้เว็บไซต์เหล่านั้น
              </p>
              <p>
                <b>5. การปฏิเสธความรับผิด</b> <br />5.1
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานจะไม่รับผิดต่อความ
                เสียหายใดๆ รวมถึง ความเสียหาย สูญเสียและ
                ค่าใช้จ่ายที่เกิดขึ้นไม่ว่าโดยตรงหรือโดยอ้อม ที่
                เป็นผลหรือสืบเนื่องจากการที่ผู้ใช้เข้าใช้เว็บไซต์นี้หรือเว็บไซต์ที่เชื่อมโยงกับเว็บไซต์นี้หรือต่อ
                4 ความเสียหาย สูญเสีย
                หรือค่าใช้จ่ายที่เกิดจากความล้มเหลวในการใช้งาน ความผิดพลาด
                การละเว้น การหยุดชะงัก ข้อบกพร่อง ความไม่สมบูรณ์คอมพิวเตอร์ไวรัส
                ถึงแม้ว่ากรมพัฒนา พลังงานทดแทนและอนุรักษ์พลังงาน
                จะได้รับแจ้งว่าอาจจะเกิดความเสียหาย สูญเสียหรือ
                ค่าใช้จ่ายดังกล่าวขึ้น
                นอกจากนี้กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                ไม่รับผิดต่อผู้ใช้ เว็บไซต์หรือบุคคลจากการเรียกร้องใดๆ
                ที่เกิดขึ้นจากบนเว็บไซต์หรือเนื้อหาใดๆ ซึ่งรวมถึงการ
                ตัดสินใจหรือการกระทำใดๆ ที่เกิดจาก
                ความเชื่อถือในเนื้อหาดังกล่าวของผู้ใช้เว็บไซต์หรือใน
                ความเสียหายใดๆ ไม่ว่าความเสียหายทางตรง หรือทางอ้อม รวมถึง
                ความเสียหายอื่นใดที่อาจ
                เกิดขึ้นได้ผู้ใช้บริการยอมรับและตระหนักดีว่ากรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                จะไม่ต้องรับผิดชอบต่อการกระทำใดของผู้ใช้บริการทั้งสิ้น
              </p>
              <p>
                <b>6. กรรมสิทธิ์และสิทธิในทรัพย์สินทางปัญญา</b> <br />6.1
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานหรือผู้ให้อนุญาตแก่เว็บไซต์
                เป็นผู้มีสิทธิตามกฎหมายแต่เพียงผู้เดียวใน
                กรรมสิทธิ์ผลประโยชน์ทั้งหมด รวมถึงสิทธิใน ทรัพย์สินทางปัญญาใดๆ
                ที่มีอยู่ในบริการ ซึ่งกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                หรือผู้ให้อนุญาตแก่เว็บไซต์เป็นผู้จัดทำขึ้น
                ไม่ว่าสิทธิเหล่านั้นจะได้รับการจดทะเบียนไว้หรือไม่ก็ ตาม
                <br />6.2
                ผู้ใช้บริการจะต้องไม่เปิดเผยข้อมูลที่กรมพัฒนาพลังงานทดแทนและอนุรักษ์
                พลังงาน กำหนดให้เป็นความลับ
                โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจาก
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน <br />6.3
                ผู้ใช้บริการจะต้องไม่ใช้ชื่อทางการค้า เครื่องหมายการค้า
                เครื่องหมายการ บริการ
                ตราสัญลักษณ์ชื่อโดเมนของเว็บไซต์โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจาก
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
              </p>
              <p>
                <b>7. กฎหมายที่ใช้บังคับ</b> <br />7.1 การตีความ
                และการบังคับตามเงื่อนไขการให้บริการฉบับนี้ให้เป็นไปตาม กฎหมายไทย
              </p>
            </div>
  </div>
</template>
